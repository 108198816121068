<template>
	<div class="page">
		<AppHeader />
		<div class="page__wrap" :style="{ backgroundColor: bgColor }">
			<RouterView />
		</div>
		<!-- <NewAppFooter /> -->
		<AppFooter />

		<AppModal
			v-if="isShowNotificationModal"
			ref="myChild"
			max-width="1150"
			:closeButton="false"
			@input="handleModalClose"
		>
			<div class="modal__holder pa-40" style="font-size: 15px">
				<div class="notification_parent" v-html="currentNotification.name['uz']"></div>
				<div class="hr_color mt-10 mb-10"></div>
				<div class="notification_parent" v-html="currentNotification.name['ru']"></div>
				<div class="hr_color mt-10 mb-10"></div>
				<div class="modal-footer mt-20 text-center">
					<app-button
						v-if="currentIndex > 0"
						theme="linear"
						height="40"
						sides="30"
						font-size="14"
						class="font_Msemibold mr-10"
						@click="prevNotification"
					>
						<img src="@/assets/icons/chevron-left.svg" width="20" height="20" alt="" />
						{{ $t("back") }}
					</app-button>
					<app-button
						v-if="currentIndex < notificationContent.length - 1"
						theme="linear"
						height="40"
						sides="30"
						font-size="14"
						class="font_Msemibold"
						@click="nextNotification"
					>
						{{ $t("next") }}
						<img src="@/assets/icons/chevron-left.svg" width="20" height="20" class="mr-10 rotate" alt="" />
					</app-button>
					<app-button
						v-else
						theme="linear"
						height="40"
						sides="30"
						font-size="14"
						class="font_Msemibold"
						@click="setStatusNotification"
					>
						{{ $t("close") }}
					</app-button>
				</div>
			</div>
		</AppModal>
	</div>
</template>

<script>
import { mapMutations } from "vuex";
import AppHeader from "../components/layouts/default/app-header/AppHeader.vue";
import AppFooter from "../components/layouts/default/app-footer/AppFooter.vue";
import AppModal from "@/components/shared-components/AppModal";
import AppButton from "@/components/shared-components/AppButton";
import LanguageService from "@/services/LanguageService";
// import NewAppFooter from "../components/layouts/default/app-footer/NewAppFooter.vue";

export default {
	name: "MainLayout",
	components: {
		AppFooter,
		AppHeader,
		AppModal,
		AppButton,
		// NewAppFooter,
	},
	data() {
		return {
			isShowNotificationModal: false,
			currentLang: LanguageService.getLanguage(),
			notificationContent: [],
			currentIndex: 0,
		};
	},

	methods: {
		...mapMutations(["setWindowWidth"]),
		setWidth() {
			this.setWindowWidth(document.documentElement.clientWidth);
		},
		getNotification() {
			this.$api.get("/api-client/Client/GetByNotifications").then(
				(response) => {
					this.notificationContent = response.data?.result;
					if (this.notificationContent.length) {
						this.checkNotificationSeen();
					}
				},
				(error) => {
					this.closeNotificationModal();
				}
			);
		},
		checkNotificationSeen() {
			const notificationLastSeenTime = localStorage.getItem("notificationLastSeenTime");
			const notificationIds = localStorage.getItem("notificationIds");
			const curDate = new Date();

			if (notificationLastSeenTime) {
				const lastSeenTime = new Date(notificationLastSeenTime);
				const diffTime = Math.abs(curDate - lastSeenTime);
				const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
				// const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

				if (diffHours > 2) {
					this.openNotificationModal();
				} else {
					const ids = JSON.parse(notificationIds);
					const newNotifications = this.notificationContent.filter((item) => !ids.includes(item.id));
					if (newNotifications.length) {
						this.openNotificationModal();
					} else {
						this.closeNotificationModal();
					}
				}
			} else {
				this.openNotificationModal();
			}
		},
		openNotificationModal() {
			this.isShowNotificationModal = true;
		},
		closeNotificationModal() {
			this.isShowNotificationModal = false;
		},
		setStatusNotification() {
			const ids = this.notificationContent.map((item) => item.id);
			localStorage.setItem("notificationLastSeenTime", new Date());
			localStorage.setItem("notificationIds", JSON.stringify(ids));
			this.closeNotificationModal();
		},
		nextNotification() {
			if (this.currentIndex < this.notificationContent.length - 1) {
				this.currentIndex++;
			}
		},
		prevNotification() {
			if (this.currentIndex > 0) {
				this.currentIndex--;
			}
		},
		handleModalClose() {
			this.isShowNotificationModal = false; // Handle the modal close event (click outside)
		},
	},
	computed: {
		bgColor() {
			if (this.$route.meta.background) {
				return `var(--color-${this.$route.meta.background})`;
			}
			return "#fff";
		},
		currentNotification() {
			return this.notificationContent[this.currentIndex] || {};
		},
	},
	created() {
		this.getNotification();
	},
	mounted() {
		this.setWidth();
		window.addEventListener("resize", this.setWidth);
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.setWidth);
	},
	watch: {
		$route() {
			if (this.notificationContent.length && this.notificationContent[this.currentIndex].id) {
				this.setStatusNotification(this.notificationContent[this.currentIndex].id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.notification_parent {
	text-indent: 15px;
	text-align: justify;
	a {
		text-decoration: underline !important;
		color: #0000ee !important;
	}
	.link_to_inst {
		text-decoration: underline !important;
		color: #0000ee !important;
	}
}
.page {
	position: relative;

	&__wrap {
		min-height: calc(100vh - 452px);
		//overflow: auto;
		margin-top: 0px;
		// background-color: #F7F9FB;
	}
}

.hr_color {
	width: 100%;
	height: 2px;
	background: #dfeaf3;
}

.rotate {
	transform: rotate(180deg);
}
</style>
