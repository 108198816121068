<template>
	<div class="navigation-drawer">
		<!-- <div class="d-flex align-center flex-wrap mb-20">
			<a :href="`${adminUrl}/#/login`">
			<AppButton theme="main"
					   sides="20"
					   radius="0"
					   class="mr-15 mb-15"
					   height="40"
					   font-size="16"
					   v-if="isMobileMedium"
			>
				Вход
			</AppButton>
			</a>
			<a :href="`${adminUrl}/#/register`">

			<AppButton theme="secondary"
					   sides="20"
					   radius="0"
					   height="40"
					   font-size="16"
					   v-if="isMobileMedium"
					   class="mb-15 mr-15"
			>
				Регистрация
			</AppButton>
			</a>


			<template v-if="$store.getters.isLoggedIn">
				<AppButton theme="secondary" sides="20"
						   radius="0"
						   font-size="14"
						   height="40"
						   :to="adminUrl"
						   class="mb-15"
				>
					<a :href="adminUrl" class="d-flex">
						<img src="../../../../assets/icons/user.svg"
							 alt=""
							 class="mr-10"
						>
						Личный кабинет
					</a>

				</AppButton>
			</template>

		</div> -->

		<AppText size="18" line-height="21" weight="600" class="mb-10 ttu text-center"> Меню </AppText>

		<div class="mobile-menu">
			<Accordion>
				<AccordionItem v-for="(item, idx) in menu" :key="idx" class="mb-10 mobile-menu__item">
					<template slot="accordion-trigger">
						<div class="accordion__header py-10 px-20" style="background-color: #f5fbff; border: none">
							<router-link :to="item.link" :style="item.children ? 'point-events: none' : ''">
								{{ item.title }}
							</router-link>
						</div>
					</template>

					<template slot="accordion-content">
						<ul class="pa-10">
							<li v-for="(children, index) in item.children" :key="index" class="ma-10">
								<router-link
									:to="children.link"
									style="color: var(--color-text) !important; font-size: 14px"
									class="tdu"
									>{{ children.title }}
								</router-link>
							</li>
						</ul>
					</template>
				</AccordionItem>
			</Accordion>
		</div>
	</div>
</template>

<script>
import BaseInput from "../../../shared-components/BaseInput";
import AppButton from "../../../shared-components/AppButton";
import BlockWrap from "../../../shared-components/BlockWrap";
import AppText from "../../../shared-components/AppText";
import Accordion from "../../../shared-components/Accordion";
import AccordionItem from "../../../shared-components/AccordionItem";

export default {
	name: "NavigationDrawer",
	components: { AccordionItem, Accordion, AppText, BlockWrap, AppButton, BaseInput },
	data() {
		return {
			menu: [
				{
					id: 1,
					title: this.$t("home"),
					link: "/",
					submenu: false,
				},
				{
					id: 2,
					title: this.$t("allSections"),
					link: "/all-sections",
					submenu: false,
				},
				{
					id: 3,
					title: this.$t("procurement"),
					link: "#2", // '/procurement'
					submenu: false,
					children: [
						{
							id: 4,
							title: this.$t("planGraphic"),
							link: "plan-graphic",
						},
						{
							id: 5,
							title: this.$t("shop"),
							link: "/product-list",
						},
						{
							id: 6,
							title: this.$t("auction"),
							link: "/auction",
						},
						{
							id: 7,
							title: this.$t("selection"),
							link: "",
						},
						{
							id: 8,
							title: this.$t("tender"),
							link: "",
						},
						{
							id: 9,
							title: this.$t("directContracts"),
							link: "",
						},
					],
				},
				{
					id: 28,
					title: this.$t("purchasingRange"),
					link: "#3", // /purchasing-range
					submenu: false,
					children: [
						{
							id: 10,
							title: this.$t("priceCriteria"),
							link: "/price-criteria-for-public-procurement",
						},
						{
							id: 11,
							title: this.$t("calculator"),
							link: "/calculator",
						},
					],
				},
				{
					id: 30,
					title: this.$t("information"),
					link: "#", // /asfas
					submenu: false,
					children: [
						{
							id: 12,
							title: this.$t("statistics"),
							link: "/statistics",
						},
						{
							id: 13,
							title: this.$t("unifiedRegisterOfTheContract"),
							link: "",
						},
						{
							id: 15,
							title: this.$t("normativeActs"),
							link: "/normative-acts",
						},
						{
							id: 16,
							title: this.$t("standardFormsAndContracts"),
							link: "/standard-forms",
						},
						{
							id: 17,
							title: this.$t("complaintsCommissionDecisions"),
							link: "",
						},
						{
							id: 18,
							title: this.$t("instructions"),
							link: "/instructions",
						},
						{
							id: 19,
							title: this.$t("catalog"),
							link: "",
						},
						{
							id: 20,
							title: this.$t("news"),
							link: "/news",
						},
						{
							id: 21,
							title: this.$t("tariffs"),
							link: "/tariffs",
						},
						{
							id: 22,
							title: this.$t("faq"),
							link: "/faq",
						},
					],
				},
				{
					id: 29,
					title: this.$t("subjectsOfProcurement"),
					link: "#4", // /subjects-of-procurement
					submenu: false,
					children: [
						{
							id: 23,
							title: this.$t("unifiedRegisterOfUnscrupulousPerformers"),
							link: "/unscrupulous-executors-register",
						},
						{
							id: 24,
							title: this.$t("singleSupplierRegister"),
							link: "/single-supplier-register",
						},
						{
							id: 35,
							title: this.$t("getAllCompanies"),
							link: "/all-companies",
						},
						{
							id: 25,
							title: this.$t("registerOfCorporateCustomers"),
							link: "/corporate-customers-register",
						},
						{
							id: 26,
							title: this.$t("registryOfBudgetCustomers"),
							link: "/budget-customers-register",
						},
						{
							id: 27,
							title: this.$t("specializedOrganizations"),
							link: "/specialized-organizations",
						},
					],
				},
			],
			adminUrl: process.env.VUE_APP_ADMIN_URL,
		};
	},
};
</script>

<style lang="scss" scoped>
.navigation-drawer {
	position: fixed;
	top: 0;
	left: 0;
	max-width: 600px;
	width: 100%;
	height: 100vh;
	z-index: 50;
	overflow-y: auto;
	padding: 30px 20px;
	background-color: white;

	&__close {
		background-color: #00d06c;
	}

	.header__search {
		margin-right: 0;

		.input__holder {
			max-width: 100%;
		}
	}
}

.mobile-menu {
	&__item {
		.accordion__header {
			transition: 0.3s;

			&::before {
				display: none;
			}

			&:hover {
				background-color: var(--color-main) !important;
				color: var(--color-secodary);
			}
		}
	}

	&__link {
	}
}

@media (max-width: 768px) {
	.navigation-drawer {
		max-width: 75% !important;
	}
}
</style>
