<template>
	<div>
		<!-- <div class="marquee marqueeLine">
			<span>
				<template v-for="i in 10"> &nbsp;{{ $t("abrobation") }}&nbsp;&nbsp; </template>
			</span>
		</div> -->

		<vue-affix-box :offset-top="0">
			<header class="header">
				<div class="header__top">
					<div class="container">
						<div class="header__inner">
							<div class="header__left">
								<div class="header__time">
									<div class="header__hour">
										<img src="../../../../assets/icons/hour.svg" alt />
										<AppText weight="500" lineHeight="14" class="appTexWidth"
											>{{ serverTime }}
										</AppText>
										<AppText weight="500" line-height="14">(GMT +5)</AppText>
									</div>
									<div class="header__data">
										<img src="../../../../assets/icons/data.svg" class="mr-10" alt />
										<AppText weight="500" lineHeight="14">{{ serverDate }} </AppText>
									</div>
								</div>
							</div>
							<div class="header__right header-user">
								<div
									style="cursor: pointer; display: flex; align-items: center"
									@click="toggleModalForNews"
									class="mr-20"
								>
									<img
										style="height: 18px"
										src="../../../../assets/icons/Stars.svg"
										class="mr-5"
										alt="Stars"
									/>
									{{ $t("ourNews") }}
								</div>
								<template>
									<a href="https://t.me/+BHzGpvwKCENkOGMy" target="_blank" class="d-flex mr-20">
										<img
											style="height: 18px"
											class="mr-5"
											src="../../../../assets/icons/FAQ.svg"
											alt="LogoOfFaq"
										/>
										{{ $t("support") }}
									</a>
								</template>
								<template>
									<div class="d-flex">
										<img src="@/assets/icons/globe.svg" alt />

										<AppDropdown
											:list="languages"
											v-model="currentLanguage"
											item-value="id"
											item-name="lang"
											padding-x="10"
											class="header__language mr-4 font_Hsemibold"
											font-size="16"
											fontWeight="600"
										/>
									</div>
								</template>
								<a
									class="header-login"
									href="/cabinet/#/login"
									v-if="!TokenService.getToken()"
									style="display: flex; align-items: center"
								>
									<img src="@/assets/images/log-in.svg" class="mr-5" />
									<span>
										{{ $t("logIn") }}
									</span>
								</a>

								<template v-if="TokenService.getToken()">
									<AppButton
										v-if="TokenService.getStructureId() === '4'"
										theme="transparent"
										sides="20"
										radius="0"
										style="color: white"
										font-size="14"
										height="40"
									>
										<a href="/cabinet/#/" class="d-flex">
											<img
												src="../../../../assets/icons/user.svg"
												alt
												style="margin-right: 10px"
											/>

											<app-text
												:title="$options.filters.translate(this.$store.state.userInfo.name)"
												line-height="16"
												max-lines="1"
												size="14"
												weight="500"
												style="max-height: unset"
												class="userNameHeader"
											>
												<span class="userNameHeader">
													{{ this.$store.state.userInfo.lastName }}
													{{ this.$store.state.userInfo.firstName }}
													{{ this.$store.state.userInfo.middleName }}
												</span>
											</app-text>
										</a>
									</AppButton>
									<a
										href="/cabinet/#/"
										v-else
										style="
											display: flex;
											align-items: center;
											text-decoration: underline;
											margin-right: 10px;
										"
									>
										<img src="../../../../assets/icons/user.svg" alt style="margin-right: 10px" />
										{{ $t("goToPersonalCabinet") }}
									</a>
								</template>

								<span
									class="ml-2"
									@click="logout"
									style="cursor: pointer"
									v-if="TokenService.getToken()"
								>
									<span>
										{{ $t("logOut") }}
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="header__bottom">
					<div class="container">
						<div class="header__inner bottom">
							<div class="header__left">
								<router-link to="/" class="logo text-uppercase">
									<img
										width="55"
										height="36"
										class="mr-10"
										src="../../../../assets/images/svg/logo.svg"
									/>
									<AppText weight="700" size="16" maxWidth="270" lineHeight="18">
										{{ $t("electronicCooperationPortal3") }}
									</AppText>
								</router-link>
							</div>

							<!-- <div class="d-flex"> -->
							<nav class="header__mid">
								<ul class="header__menu" v-if="!isDesktopSmall">
									<li
										class="header__menu-item"
										v-for="(item, idx) in menu"
										:key="idx"
										v-on="handleShowDropdown(item.id)"
									>
										<template v-if="item && item.hard">
											<a
												:style="item.children ? 'pointer-events: none' : ''"
												class="header__menu-link"
												:href="item.link"
												target="_blank"
											>
												<template v-if="item.icon">
													<img class="link_icon" :src="item.icon" alt="Icon" />
												</template>
												{{ item.title }}
											</a>
										</template>

										<template v-else>
											<template v-if="item.icon">
												<img class="link_icon" :src="item.icon" alt="Icon" />
											</template>
											<router-link
												:to="item.link"
												class="header__menu-link"
												exact
												:style="
													(item.children ? 'pointer-events: none' : '',
													item.main ? 'color: #114393; text-decoration: underline;' : '')
												"
												>{{ item.title }}
											</router-link>
										</template>

										<transition name="slide">
											<div class="dropdown__menu" v-if="item.id === activeId">
												<ul class="dropdown__menu-inner">
													<li
														class="dropdown__menu-item"
														v-for="(submenu, idx) in item.children"
														:key="idx"
														v-on="handleShowDropdownInner(submenu.id)"
													>
														<a
															v-if="submenu.external && !submenu.download"
															:href="submenu.link"
															class="dropdown__menu-link"
															target="_blank"
															:class="{ 'has-dropdown': submenu.length }"
															>{{ submenu.title }}</a
														>
														<router-link
															:to="submenu.link"
															v-if="!submenu.download && !submenu.external"
															class="dropdown__menu-link"
															:class="{ 'has-dropdown': submenu.length }"
															>{{ submenu.title }}
														</router-link>
														<template v-if="submenu.download && !submenu.external">
															<a
																class="dropdown__menu-link"
																:href="`${
																	submenu.fileUrl +
																	$store.state.clng +
																	'.' +
																	submenu.type
																}`"
																:download="`${submenu.title}.${submenu.type}`"
															>
																{{ submenu.title }}
															</a>
														</template>
													</li>
												</ul>
											</div>
										</transition>
									</li>
									<li class="header__menu-item">
										<a
											href="https://yarmarka.cooperation.uz/"
											target="_blank"
											class="header__menu-link"
											>{{ $t("onlineFair") }}</a
										>
									</li>
								</ul>
							</nav>

							<div class="header__right mobile__header__right">
								<template v-if="!TokenService.getToken()">
									<AppButton
										sides="10"
										class="hamburger__menu_btn"
										@click="showNavigationDrawer"
										v-if="isDesktopSmall"
									>
										<img src="../../../../assets/icons/hamburger.svg" alt />
									</AppButton>
								</template>
							</div>
							<!-- </div> -->
						</div>
					</div>
				</div>
			</header>
		</vue-affix-box>
		<transition name="slide-right">
			<NavigationDrawer v-if="navigationDrawer" />
		</transition>

		<transition name="fade">
			<div class="overlay" v-if="navigationDrawer" @click="navigationDrawer = false"></div>
		</transition>
		<AppModal max-width="800" v-if="modalForNews" v-model="modalForNews">
			<div class="modal-content">
				<!-- Title -->
				<div class="text-center text-bold" style="font-size: 20px">
					{{ $t("titleOfFeatutures") }}
				</div>
				<!-- One -->
				<div>
					<img src="../../../../assets/images/feautures/F2.svg" alt="F2.svg" />
					<div>
						<p v-html="$t('feauture2Title')"></p>
						<ul>
							<li>-{{ $t("feature2Text1") }}</li>
							<li>-{{ $t("feature2Text2") }}</li>
						</ul>
						<p>{{ $t("feauture2FinalText") }}</p>
					</div>
				</div>
				<!-- Two -->
				<div>
					<img src="../../../../assets/images/feautures/F12.svg" alt="F12.svg" />
					<p v-html="$t('feauture12')"></p>
				</div>
				<!-- Three -->
				<div>
					<img src="../../../../assets/images/feautures/F7.svg" alt="F7.svg" />
					<p v-html="$t('feauture7')"></p>
				</div>
				<!-- Four -->
				<div>
					<img src="../../../../assets/images/feautures/F3.svg" alt="F3.svg" />
					<div>
						<p v-html="$t('feauture3Title')"></p>
						<ul>
							<li>-{{ $t("feauture3Text1") }}</li>
							<li>-{{ $t("feauture3Text2") }}</li>
							<li>-{{ $t("feauture3Text3") }}</li>
							<li>-{{ $t("feauture3Text4") }}</li>
							<li>-{{ $t("feauture3Text5") }}</li>
							<li>-{{ $t("feauture3Text6") }}</li>
						</ul>
					</div>
				</div>
				<!-- Five -->
				<div>
					<img src="../../../../assets/images/feautures/F1.svg" alt="F1.svg" />
					<p v-html="$t('feature1')"></p>
				</div>
				<!-- Six -->
				<div>
					<img src="../../../../assets/images/feautures/F4.svg" alt="F4.svg" />
					<p v-html="$t('feauture4')"></p>
				</div>
				<!-- Seven -->
				<!-- <div>
					<img src="../../../../assets/images/feautures/F5.svg" alt="F5.svg" />
					<p v-html="$t('feauture5')"></p>
				</div> -->
				<!-- Eight -->
				<div>
					<img src="../../../../assets/images/feautures/F6.svg" alt="F6.svg" />
					<p v-html="$t('feauture6')"></p>
				</div>
				<!-- Nine -->
				<div>
					<img src="../../../../assets/images/feautures/F8.svg" alt="F8.svg" />
					<p v-html="$t('feauture8')"></p>
				</div>
				<!-- Ten -->
				<div>
					<img src="../../../../assets/images/feautures/F9.svg" alt="F9.svg" />
					<p v-html="$t('feauture9')"></p>
				</div>
				<!-- Eleven -->
				<div>
					<img src="../../../../assets/images/feautures/F10.svg" alt="F10.svg" />
					<div>
						<p v-html="$t('feauture10Title')"></p>
						<ul>
							<li>-{{ $t("feauture10Text1") }}</li>
							<li>-{{ $t("feauture10Text2") }}</li>
							<li>-{{ $t("feauture10Text3") }}</li>
						</ul>
					</div>
				</div>
				<!-- Twelve -->
				<div>
					<img src="../../../../assets/images/feautures/F11.svg" alt="F11.svg" />
					<p v-html="$t('feauture11')"></p>
				</div>
				<!-- Button -->
				<div class="mb-2" v-if="isLoggedIn">
					<app-button
						@click="setReadedStatus"
						theme="main"
						style="
							padding: 0 10px;
							min-width: 150px;
							font-size: 16px;
							max-height: min-content;
							line-height: 50px;
							margin: 5px auto;
						"
					>
						{{ $t("readTheFeautures") }}
					</app-button>
				</div>
			</div>
		</AppModal>
	</div>
</template>

<script>
import "./header.scss";
import AppModal from "@/components/shared-components/AppModal";
import AppText from "../../../shared-components/AppText";
import AppDropdown from "../../../shared-components/AppDropdown";
import AppButton from "../../../shared-components/AppButton";
import NavigationDrawer from "./NavigationDrawer.vue";
import LanguageService from "@/services/LanguageService";
import TokenService from "@/services/TokenService";
import VueAffixBox from "vue-affix-box";

export default {
	name: "AppHeader",
	components: {
		NavigationDrawer,
		AppButton,
		AppDropdown,
		AppText,
		VueAffixBox,
		AppModal,
	},
	data() {
		return {
			TokenService,
			currentLanguage: LanguageService.getLanguage(),
			languages: LanguageService.languages,
			menu: [
				{
					id: 2,
					title: this.$t("allSections"),
					link: "/all-sections",
					submenu: false,
					icon: require("../../../../assets/icons/menu.svg"),
				},
				{
					id: 3,
					title: this.$t("procurement"),
					link: "#2", // '/procurement'
					submenu: false,
					children: [
						{
							id: 4,
							title: this.$t("planGraphic"),
							link: "/plan-graphic",
						},
						{
							id: 28,
							title: this.$t("purchasingRange"),
							link: "#3", // /purchasing-range
							submenu: false,
						},
						{
							id: 5,
							title: this.$t("shop"),
							link: "/product-list",
						},
						{
							id: 10,
							title: this.$t("priceCriteria"),
							link: "/price-criteria-for-public-procurement",
						},
						{
							id: 11,
							title: this.$t("calculator"),
							link: "/calculator",
						},
						// {
						// 	id: 6,
						// 	title: this.$t("auction"),
						// 	link: "/auction",
						// },
						// {
						// 	id: 7,
						// 	title: this.$t("selection"),
						// 	link: "/selection",
						// },
						// {
						// 	id: 8,
						// 	title: this.$t("tender"),
						// 	link: "/tender",
						// },
						// {
						// 	id: 9,
						// 	title: this.$t("directContracts"),
						// 	link: "/direct-purchases",
						// },
					],
				},

				// {
				// 	id: 1,
				// 	title: this.$t("shop"),
				// 	link: "/product-list",
				// 	submenu: false,
				// 	main: true,
				// },
				{
					id: 30,
					title: this.$t("information"),
					link: "#", // /asfas
					submenu: false,
					children: [
						{
							id: 18,
							title: this.$t("instructions"),
							link: "/instructions",
						},
						{
							id: 12,
							title: this.$t("statistics"),
							link: "/statistics",
						},
						{
							id: 13,
							title: this.$t("unifiedRegisterOfTheContract"),
							link: "https://stat-new.cooperation.uz/contracts",
							external: true,
						},
						{
							id: 32,
							title: this.$t("registerOfActiveTrades"),
							link: "/active-trades",
						},
						{
							id: 24,
							title: this.$t("registerOfManufacturersDealers"),
							link: "/single-supplier-register",
						},
						{
							id: 14,
							title: this.$t("standardFormsAndContracts"),
							link: "/standard-forms",
						},
						{
							id: 35,
							title: this.$t("getAllCompanies"),
							link: "/all-companies",
						},
						// {
						// 	id: 15,
						// 	title: this.$t("normativeActs"),
						// 	link: "/normative-acts",
						// },
						// {
						// 	id: 17,
						// 	title: this.$t("complaintsCommissionDecisions"),
						// 	link: "",
						// },
						// {
						// 	id: 19,
						// 	title: this.$t("catalog"),
						// 	link: "http://tasniflagich.mf.uz",
						// 	external: true,
						// },
						{
							id: 21,
							title: this.$t("tariffs"),
							link: "/tariffs",
						},
						{
							id: 31,
							title: this.$t("onlineExhibitions"),
							// link: "https://yarmarka.cooperation.uz/",
							link: "/online-exhibitions",
							hard: false,
						},
						{
							id: 33,
							title: this.$t("code2022"),
							download: true,
							fileUrl: "/docs/inst/code",
							type: "pdf",
						},
					],
				},
				{
					id: 29,
					title: this.$t("subjectsOfProcurement"),
					link: "#4", // /subjects-of-procurement
					submenu: false,
					children: [
						{
							id: 23,
							title: this.$t("unifiedRegisterOfUnscrupulousPerformers"),
							link: "/unscrupulous-executors-register",
						},
						{
							id: 25,
							title: this.$t("registerOfCorporateCustomers"),
							link: "/corporate-customers-register",
						},
						{
							id: 26,
							title: this.$t("registryOfBudgetCustomers"),
							link: "/budget-customers-register",
						},
						// {
						// 	id: 27,
						// 	title: this.$t("specializedOrganizations"),
						// 	link: "/specialized-organizations",
						// },
						{
							id: 28,
							title: this.$t("singleSupplierRegister"),
							link: "/single-supplier",
						},
						{
							id: 35,
							title: this.$t("getAllCompanies"),
							link: "/all-companies",
						},
					],
				},
				{
					id: 20,
					title: this.$t("news"),
					link: "/news",
				},
			],
			navigationDrawer: false,
			activeId: null,
			adminUrl: process.env.VUE_APP_ADMIN_URL,
			languageContent: false,
			modalForNews: false,
		};
	},
	created() {
		if (TokenService.getToken() && TokenService.getStructureId()) {
			this.getUserInfo();
		}
	},
	// mounted() {
	// 	function Marquee(selector, speed) {
	// 		const parentSelector = document.querySelector(selector);
	// 		const clone = parentSelector.innerHTML;
	// 		const firstElement = parentSelector.children[0];
	// 		let i = 10;
	// 		parentSelector.insertAdjacentHTML("beforeend", clone);
	// 		parentSelector.insertAdjacentHTML("beforeend", clone);

	// 		setInterval(function () {
	// 			firstElement.style.marginLeft = `-${i}px`;
	// 			if (i > firstElement.clientWidth) {
	// 				i = 0;
	// 			}
	// 			i = i + speed;
	// 		}, 0);
	// 	}
	// 	window.addEventListener("load", Marquee(".marquee", 0.2));
	// },
	methods: {
		async setReadedStatus() {
			try {
				this.$store.commit("setLoadingStatus", true);
				const response = await this.$api.get("api-admin/Home/IsQuestionnaire");
				this.toggleModalForNews();
				this.$notification.success(this.$t("notification.success"));
				this.$store.commit("setQuestionaree", true);
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		toggleModalForNews() {
			this.modalForNews = !this.modalForNews;
		},
		getUserInfo() {
			let url;
			let userType = TokenService.getStructureId();
			if (userType === "4") {
				url = "GetByUsers";
			} else {
				url = "GetBy";
			}
			this.getCompanyInfo(url);
		},
		getCompanyInfo(url) {
			// if (Object.keys(this.$store.state.userInfo).length > 0) return;
			return this.$api
				.get("/api-company/Company/" + url)
				.then(
					(response) => {
						this.$store.commit("setUserInfo", response.data.result);
					},
					(error) => {}
				)
				.finally(() => {});
		},

		showNavigationDrawer() {
			this.navigationDrawer = !this.navigationDrawer;
		},

		logout() {
			TokenService.removeAll();
			setTimeout(() => {
				window.location.reload();
			}, 0);
		},
		handleShowDropdown(id) {
			if (this.isMobile) {
				return {
					click: () => (this.activeId = this.activeId === id ? null : id),
				};
			}
			return {
				click: () => (this.activeId = this.activeId === id ? null : id),
				mouseenter: () => (this.activeId = id),
				mouseleave: () => (this.activeId = null),
			};
		},

		handleShowDropdownInner(id) {
			if (this.isMobile) {
				return {
					click: () => (this.subActiveId = this.subActiveId === id ? null : id),
				};
			}
			return {
				click: () => (this.subActiveId = this.subActiveId === id ? null : id),
				mouseenter: () => (this.subActiveId = id),
				mouseleave: () => (this.subActiveId = null),
			};
		},

		show() {
			this.languageContent = !this.languageContent;
		},
	},
	watch: {
		currentLanguage(val) {
			this.$store.commit("setLanguage", val);
			LanguageService.setLanguage(val, this.$i18n);
		},
		$route() {
			this.navigationDrawer = false;
		},
	},
	computed: {
		langs() {
			return this.language.filter((item) => item.lang !== this.$i18n.locale);
		},
		serverTime() {
			let time = this.$store.state.serverTime;
			return this.$moment(new Date(time)).format("HH:mm:ss");
		},
		serverDate() {
			let time = this.$store.state.serverTime;
			return this.$moment(new Date(time)).format("DD.MM.YYYY");
		},
		isLoggedIn() {
			return (
				this.$store.state.userInfo.tin &&
				this.$store.state.userInfo.id &&
				this.$store.state.userInfo.isQuestionnaire === false
			);
		},
	},
};
</script>

<style lang="scss">
.is-fixed {
	z-index: 100 !important;
}
.mobile__header__right {
	display: none;
}

@media (max-width: 560px) {
	.mobile__header__right {
		display: flex;
	}
}
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.link_icon {
	height: 12px;
	margin-right: -5px;
}
.marquee {
	overflow: hidden;
	display: flex;
}

.modal-content {
	padding: 10px 20px;
	background: #fff;
	max-height: 90vh;
	overflow-y: auto;
	& > div {
		margin: 25px 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	img {
		margin-right: 15px;
		display: inline-block;
		height: 40px;
		width: 40px;
		object-position: center;
		object-fit: fill;
	}
	li {
		margin-top: 5px;
		margin-left: 10px;
	}
}

.marquee span {
	font-size: 0.8rem;
	white-space: nowrap;
	text-transform: uppercase;
}
.marqueeLine {
	padding: 2px 0;
	background: #cebe2b;
	color: #ffffff;
	font-size: 0.9rem;
	font-weight: 700;
}
.header__top {
	color: #ffffff;
}
.customClassForSign {
	height: 40px;
	line-height: normal;
}
.logo {
	img {
		transform: scale(1.2);
	}
}
.header__bottom {
	background-color: white;

	.header__right {
		flex-wrap: nowrap;
	}
}

.header__inner {
	&.bottom {
		flex-wrap: nowrap;
	}
}

.hamburger__menu_btn {
	margin-right: 15px;
}
.header__data {
	color: white;
	display: flex;
	align-items: center;
}
.header__hour {
	color: white;
	display: grid;
	grid-template-columns: auto 1fr 1fr;
	align-items: center;
}
.header__time {
	display: flex;
	align-items: center;
	gap: 5px;
}
@media (max-width: 651px) {
	.hamburger__menu_btn {
		margin-right: 0;
	}
	.header-login {
		display: none !important;
	}
	.header__inner.bottom {
		display: grid;
		grid-template-columns: 1fr 0px 50px;
	}
}

.userNameHeader {
	white-space: nowrap;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 330px !important;
	overflow-x: hidden !important;
}
.modal__content {
	max-height: 90vh !important;
	overflow-y: auto !important;
}
</style>
