<template>
	<div class="footer">
		<div class="container mb-10">
			<div class="footer__left">
				<div class="footer__contacts">
					<div class="footer__contacts-item mb-20">
						<a href="#!" class="text-uppercase align-center">
							<img src="../../../../assets/icons/logo-white.svg" class="mr-15" />
							<AppText weight="700" size="16" lineHeight="20" maxWidth="250">{{
								$t("electronicCooperationPortal3")
							}}</AppText>
						</a>
					</div>
					<div class="footer__contacts-item mb-20">
						<a href="#" class="font_Hregular"
							><span>{{ $t("address") }}:</span
							><span class="font_Hregular ml-10">
								{{ $t("cooperAdress") }}
							</span>
						</a>
					</div>
					<div class="footer__contacts-item mb-20 footer_location">
						<a target="_blank" href="https://maps.app.goo.gl/iEfUQqFHArYKVL7LA"
							><img src="../../../../assets/images/google.png" alt="Google" />Google</a
						>
						<a target="_blank" href="https://yandex.uz/maps/-/CDGarO~F"
							><img src="../../../../assets/images/yandex.png" alt="yandex" />Yandex</a
						>
					</div>
					<div class="footer__contacts-item mb-20">
						<a href="tel:+998-71-203-25-55" class="font_Hregular"
							>{{ $t("phone") }}: <span class="font_Hregular ml-10"> (71) 203 25 55</span></a
						>
					</div>
					<div class="footer__contacts-item">
						<a href="mailto:manager@cooperation.uz" class="font_Hregular"
							>{{ $t("eMail") }}: &nbsp;
							<span class="text-underline font_Hregular">manager@cooperation.uz</span>
						</a>
					</div>
					<div class="footer__contacts-item d-flex mt-10">
						<AppText size="14" line-height="18" class="d-flex align-center mr-20 font_Hregular" weight="500"
							>{{ $t("socialMedia") }}:</AppText
						>
						<div>
							<a href="https://www.facebook.com/cooperation.uz" target="_blank" class="footer-logo mr-10">
								<img src="../../../../assets/icons/facebook-icon.svg" alt="" />
							</a>
							<a href="https://t.me/uz_cooperation" target="_blank" class="footer-logo mr-10">
								<img src="../../../../assets/icons/telegram-icon.svg" alt="" />
							</a>
							<a
								href="https://www.instagram.com/cooperation_uz"
								target="_blank"
								class="footer-logo mr-10"
							>
								<img src="../../../../assets/icons/instagram-icon.svg" alt="" />
							</a>
							<a href="https://www.youtube.com/@cooperationuz" target="_blank" class="footer-logo mr-10">
								<img src="../../../../assets/icons/youtube-icon.svg" alt="" />
							</a>
						</div>
					</div>
				</div>
				<div class="footer__column">
					<ul class="footer__menu">
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/" class="footer__menu-link">
								{{ $t("home") }}
							</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/about" class="footer__menu-link">
								{{ $t("aboutPortal") }}
							</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/contacts" class="footer__menu-link">{{ $t("contacts") }}</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/product-list" class="footer__menu-link">{{ $t("search") }}</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/normative-acts" class="footer__menu-link">{{
								$t("normativeActs")
							}}</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/public-offer" class="footer__menu-link">{{
								$t("publicOffer")
							}}</router-link>
						</li>
					</ul>
				</div>
				<div class="footer__column">
					<ul class="footer__menu">
						<!-- <li class="footer__menu-item font_Hmedium">
									<router-link to="/all-sections" class="footer__menu-link">{{
										$t("listOfRecommended")
									}}</router-link>
								</li> -->
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/volumes" class="footer__menu-link"
								>{{ $t("theVolumeOfIndustrial") }}
							</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/all-sections" class="footer__menu-link">{{
								$t("proposalForCooperation")
							}}</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/import-and-export" class="footer__menu-link">{{
								$t("inportAndExportVolme")
							}}</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/all-sections" class="footer__menu-link"
								>{{ $t("stateRealEstate") }}
							</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/plan-graphic" class="footer__menu-link">{{
								$t("budgetPurtchases")
							}}</router-link>
						</li>
						<li class="footer__menu-item font_Hmedium">
							<router-link to="/plan-graphic" class="footer__menu-link">{{
								$t("corporateProcurement")
							}}</router-link>
						</li>
					</ul>
				</div>
				<div class="footer__column">
					<AppText size="14" :weight="600" class="mb-10 font_Hsemibold"
						>{{ $t("operatorDetailsForNon") }}:</AppText
					>
					<AppText size="14" class="mb-10">{{ $t("electronicCooperationPortal") }}</AppText>
					<AppText size="14" class="mb-10 font_Hregular"
						>{{ $t("inn") }}:
						<span class="font_Hregular" style="margin-left: 12px">307442330</span></AppText
					>
					<AppText size="14" class="mb-10 font_Hregular"
						>{{ $t("mfo") }}: <span class="font_Hregular ml-10">00419</span></AppText
					>
					<AppText size="14" class="mb-20 font_Hregular"
						>{{ $t("pr") }}:
						<span class="font_Hregular" style="margin-left: 19px">20208000205235588200</span>
					</AppText>

					<AppText size="14" :weight="600" class="mb-10 font_Hsemibold"
						>{{ $t("requisitesForBudgetary") }}:</AppText
					>
					<AppText size="14" class="mb-10">{{ $t("specialist") }}:</AppText>
					<AppText size="14" class="font_Hregular"
						>{{ $t("ls") }}:
						<span class="font_Hregular" style="margin-left: 18px">700110860262947950600262001 </span>
					</AppText>
				</div>
			</div>
		</div>
		<hr />
		<div class="container poweredBy">
			<img src="@/assets/icons/rtm.svg" alt="rtm" />{{ $t("poweredbyCDT") }} &copy;
			{{ new Date().getFullYear() }}
		</div>
	</div>
</template>

<script>
import AppText from "../../../shared-components/AppText";
import BlockWrap from "../../../shared-components/BlockWrap";

export default {
	name: "AppFooter",
	components: { BlockWrap, AppText },
};
</script>

<style scoped lang="scss">
.footer {
	width: 100%;
	background: #1f2d43;
	color: white;
	font-size: 14px;
	padding: 50px 0 67px 0;
	&__left {
		display: grid;
		grid-template-columns: 4fr 1fr 3fr 3fr;
		width: 100%;
		gap: 20px;
	}
	&__contacts {
		&-item {
			color: white;
			display: flex;
			align-items: center;
			min-width: 352px;
			a {
				display: inline-flex;
			}
		}
	}
	&__menu {
		&-item {
			margin-bottom: 16px;
			min-width: 155px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__column {
		// margin: 10px  20px 0;
		min-width: 200px;
		&:last-child {
			margin-right: 0;
		}
	}
}

@media (max-width: 1300px) {
	.footer__column {
		&:last-child {
			margin-bottom: 0;
		}
	}
	.footer {
		&__left {
			grid-template-columns: repeat(2, 2fr);
			gap: 30px;
		}
	}
}

@media (max-width: 600px) {
	.footer {
		padding: 20px;
		&__top {
			flex-direction: column;
		}
		&__left {
			width: 100%;
		}
		&__bottom {
			display: block;
			text-align: center;
			.footer__left {
				margin-bottom: 20px;
			}
			.footer__right {
				max-width: 100%;
				grid-row-gap: 15px;
			}
		}
		&__contacts {
			width: 100% !important;
		}
	}
}

@media (max-width: 870px) {
	.footer {
		&__left {
			grid-template-columns: repeat(1, 1fr);
			gap: 30px;
		}
	}
	.footer__column {
		text-align: center;
	}
	.footer_location {
		max-width: 200px;
		margin: 20px auto;
	}
	.footer__contacts-item {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		a,
		p {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		div {
			width: max-content;
			display: flex;
			align-items: center;
		}
	}
	.footer__menu {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}
}

.footer_location {
	display: flex;
	align-items: flex-start;
	img {
		margin-right: 5px;
		height: 20px;
	}
	a {
		display: flex;
		align-items: flex-end;
		margin-right: 10px;
		&:first-child {
			margin-right: 10px;
		}
	}
}
.footer {
	padding-bottom: 20px;
}
.poweredBy {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 auto;
	margin-top: 20px;
	img {
		display: inline-block;
		margin-right: 13px;
		cursor: pointer;
		height: 30px;
		margin-bottom: 3px;
	}
}
</style>
